import { Component } from "react";
import { LogoLightTheme, LogoDarkTheme } from '../../assets/svg/index';

class Logo extends Component {
    render() {
        const size = this.props?.size || 56
        const style = { width: `${size}px`, height: `${size}px` }
        if(this.props.theme === 'dark') {
            return <LogoDarkTheme style={style}/>
        } else {
            return <LogoLightTheme style={style}/>
        }
    }
}

export default Logo